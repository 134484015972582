import type { FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';

export async function transformResponseToCSV(
  baseQueryReturnValue: Response,
  filename: string = 'download.csv',
): Promise<void> {
  const blob = await baseQueryReturnValue.blob();
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
}

/** TIN Dimagh API uses `data.data` syntax for responses */
export function transformResponse<T>(baseQueryReturnValue: { data: T }, _meta?: FetchBaseQueryMeta): T {
  return baseQueryReturnValue?.data;
}
