import { UserType } from 'src/common/types/user.enum';
import { Api } from '../common/Api';
import { apiResources, IS_DEMO_MODE_ACTIVATED } from '../common/Constants';
import { isValidPassword, isValidPIN } from '../common/DataFormatUtils';
import LocalCache from '../common/LocalCache';
import { WithRequired } from '../common/types/UtilityTypes.type';
import { ErrorMessage } from '../shipment/Shipment';
import { getDemoTransactionData } from './TransactionHistoryDemoData';

const missingFieldsError = 'Required fields are missing';

export const UserTypes = (): Record<keyof typeof UserType, { id: number; value: string }> => ({
  SHIPPER: { id: 1, value: 'Shipper' },
  PARTNER: { id: 2, value: 'Partner' },
  DRIVER: { id: 3, value: 'Driver' },
  INTERNAL: { id: 4, value: 'Internal User' },
  DEMAND_ADDA: { id: 5, value: 'Broker' },
});

export enum BlockTypes {
  BLOCK = 1,
  UNBLOCK = 2,
}

export type TPreferences = {
  theme: { logoUrl: string; primary: string; secondary: string };
  terms: { shipperRate: string; paymentTypes: string[]; shipmentTypes: string[]; vehicleType: string };
  clientName: string;
  language: 'en' | 'fr';
  weightUnit: string;
  distanceUnit: 'km' | 'ml';
  currency: string;
  expandedTripStatuses: boolean;
  tripVerifications: boolean;
  vehicleTypes: Array<number>;
  documentTypes: Array<number>;
  googleMaps: { apiKey: string; mapId: string };
  podRequired: boolean;
};

export interface User {
  id?: number;
  companyId?: number;
  companyName: string;
  countryId: number;
  email?: string;
  fullName: string;
  locale?: string;
  password?: string;
  phoneNumber: string;
  ssn?: string;
  userType: UserType;
  roleId?: number;
  roleName?: number;
  permissions?: number[];
  isVerified?: boolean;
  isBlocked?: boolean;
  userVerificationStatusName?: string;
  userVerifiedByName?: any;
  userVerificationDate?: Date;
  userVerificationRemarks?: string;
  totalPages?: number;
  preferences?: TPreferences;
}

interface CreateUserArgs extends User {
  stateId?: number;
  cityId?: number;
  pin?: string;
}

export interface BlockReasons {
  id: number | null;
  reason: string;
}

export interface UserBlockedDetails {
  reason: string;
  actionType: string;
  actionPerformerName: string;
  createdAt: Date;
}
export interface TransactionHistory {
  creditAmount: number;
  debitAmount: number;
  accountBalance: number;
  transactionResponseDtos: Transaction[];
}

interface Transaction {
  transactionId: number;
  transactionType: TransactionTypes;
  transactionMessage: string;
  transactionAmount: number;
  creationDate: Date;
}

export enum TransactionTypes {
  Debit = 'DEBIT',
  Credit = 'CREDIT',
}

class UserApi {
  private readonly api = new Api();

  async fetchUser(id: number): Promise<User> {
    return await this.api.getResource(`${apiResources.user}/${id}`);
  }

  async fetchUserByUsername(username: string, isEmail: boolean): Promise<User> {
    return await this.api.getResource(`${apiResources.user}/username/${username}?isEmail=${isEmail}`);
  }

  async createUser(user: CreateUserArgs): Promise<User> {
    return await this.api.createResource(`${apiResources.user}`, user);
  }

  async updateUser(user: User): Promise<User> {
    return await this.api.updateResource(apiResources.user, user);
  }
  async updateVerificationUser(props: any): Promise<string> {
    return await this.api.createResource(apiResources.updateUserVerfication, props);
  }

  async fetchBlockReasons(reasonType: number, userType: number): Promise<BlockReasons[]> {
    return await this.api.getResource(
      `${apiResources.user}/blockReasons?reasonType=${reasonType}&userType=${userType}`,
    );
  }

  async fetchBlockDetailsByUser(username: string, userType: number): Promise<UserBlockedDetails> {
    return await this.api.getResource(`${apiResources.user}/${username}/blockDetails/${userType}`);
  }

  async blockUserById(
    username: string,
    userType: number,
    block: boolean,
    reasonId: number | null,
    reason: string | null,
  ) {
    return await this.api.createResource(`${apiResources.user}/${username}/update-status`, {
      userType: userType,
      block: block,
      reasonId: reasonId,
      reason: reason,
    });
  }

  async invitePartnerToPrivatePool(userId: number) {
    return await this.api.createResource(`${apiResources.user}/username/${userId}/private-pool`);
  }

  async fetchTransactionHistory(userId: number, days = 0): Promise<TransactionHistory> {
    const demoTransactionHistory = getDemoTransactionData();
    if (IS_DEMO_MODE_ACTIVATED && userId === 90) return demoTransactionHistory;

    return await this.api.getResource(
      `${apiResources.transactionHistory}`
        .replace('{accountHolderId}', `${userId}`)
        .replace('{accountHolderTypeId}', `${1}`)
        .replace('{days}', `${days}`),
    );
  }

  async fetchInternalUsers(page = 0, searchParam?: { [id: string]: string }): Promise<User[]> {
    return await this.api.getResource(`${apiResources.internalUsers}/`, {
      page: page,
      ...searchParam,
    });
  }

  async deleteInternalUsers(userId?: number): Promise<string> {
    return await this.api.deleteResource(`${apiResources.user}/${userId}`);
  }

  async resetPasswordByEmailToken(
    token: string | undefined,
    password: string | undefined,
    confirmPassword: string | undefined,
    isDriver: number | 0,
  ) {
    return await this.api.createResource(
      `${isDriver == 1 ? apiResources.resetPasswordForDriver : apiResources.resetPasswordForUser}?token=${token}`,
      {
        password: password,
        confirmPassword: confirmPassword,
      },
    );
  }

  async resetPasswordByUserId(
    userId: string | undefined,
    password: string | undefined,
    confirmPassword: string | undefined,
    isDriver: number | 0,
  ) {
    const url = isDriver === 1 ? apiResources.changePasswordForDriver : apiResources.changePasswordForUser;

    return await this.api.createResource(url.replace('{userId}', userId ?? ''), {
      password: password,
      confirmPassword: confirmPassword,
    });
  }

  async resetPINByUserId(
    userId: string | undefined,
    pin: string | undefined,
    confirmPin: string | undefined,
    isDriver: number | 0,
  ) {
    const url = isDriver === 1 ? apiResources.changePasswordForDriver : apiResources.changePasswordForUser;

    return await this.api.createResource(url.replace('{userId}', userId ?? ''), {
      pin,
      confirmPin,
    });
  }
}

export async function fetchUserById(id: number): Promise<User> {
  const api = new UserApi();
  return api.fetchUser(id);
}

export async function fetchBlockingReasons(reasonType: number, userType: number): Promise<BlockReasons[]> {
  const api = new UserApi();
  return api.fetchBlockReasons(reasonType, userType);
}

export async function fetchBlockingDetails(username: string, userType: number): Promise<UserBlockedDetails> {
  const api = new UserApi();
  return api.fetchBlockDetailsByUser(username, userType);
}

export async function blockUser(
  username: string,
  userType: number,
  block: boolean,
  reasonId: number | null,
  reason: string | null,
) {
  const api = new UserApi();
  return api.blockUserById(username, userType, block, reasonId, reason);
}

export async function fetchUserByUsername(username: string, isEmail: boolean): Promise<User> {
  const api = new UserApi();
  return api.fetchUserByUsername(username, isEmail);
}

export function saveUserInCache(user: User) {
  if (user.id) {
    LocalCache.saveUser(JSON.stringify(user));
  }
}

export function loadUserFromCache(): User {
  return JSON.parse(LocalCache.fetchUser() ?? '');
}

export async function invitePartnerToPrivatePool(userId: number) {
  const api = new UserApi();
  return await api.invitePartnerToPrivatePool(userId);
}

export async function fetchTransactionHistory(userId: number, days = 60): Promise<TransactionHistory> {
  const api = new UserApi();
  return await api.fetchTransactionHistory(userId, days);
}

export async function fetchInternalUsers(page = 1, searchBy = '', searchKeyword = ''): Promise<User[]> {
  const api = new UserApi();
  return await api.fetchInternalUsers(page - 1, { [searchBy]: searchKeyword });
}

export async function deleteInternalUsers(userId?: number): Promise<string> {
  const api = new UserApi();
  return await api.deleteInternalUsers(userId);
}

export async function createUser(
  name: string,
  company: string,
  mobile: string,
  userType: UserType,
  roleId?: number,
  email?: string,
  password?: string,
  confirmPassword?: string,
  ssn?: string,
) {
  if (!name || !mobile || !email || !roleId || roleId <= 0) {
    throw missingFieldsError;
  }

  if (!isValidPassword(password)) {
    throw ErrorMessage.PasswordsValidation;
  }

  if (password && password !== confirmPassword) {
    throw 'Passwords must match';
  }
  const api = new UserApi();
  await api.createUser({
    userType: userType,
    roleId: roleId,
    companyName: company,
    countryId: 1,
    password: password ?? Math.random().toString(36).slice(-8),
    email,
    fullName: name,
    locale: '',
    phoneNumber: mobile,
    ssn,
  });
}

export async function updatePartner(partner: User) {
  if (!partner.fullName || !partner.phoneNumber) {
    throw missingFieldsError;
  }

  const api = new UserApi();
  await api.updateUser(partner);
}

export async function updateUser(user: User) {
  if (!user.fullName || !user.email) {
    throw missingFieldsError;
  }

  const api = new UserApi();
  await api.updateUser(user);
}

export async function updateVerificationUser(props: any) {
  const api = new UserApi();
  await api.updateVerificationUser(props);
}

export async function resetPasswordByEmailToken(
  token: string | undefined,
  password: string | undefined,
  confirmPassword: string | undefined,
  isDriver: number | 0,
) {
  if (!token || !password || !confirmPassword) {
    throw missingFieldsError;
  }
  const api = new UserApi();
  return await api.resetPasswordByEmailToken(token, password, confirmPassword, isDriver);
}

export async function resetPasswordByUserId(
  userId: string | undefined,
  password: string | undefined,
  confirmPassword: string | undefined,
  isDriver: number | 0,
) {
  if (!userId || !password || !confirmPassword) {
    throw missingFieldsError;
  }

  if (!isValidPassword(password)) {
    throw ErrorMessage.PasswordsValidation;
  }

  const api = new UserApi();
  return await api.resetPasswordByUserId(userId, password, confirmPassword, isDriver);
}

export async function resetPINByUserId(
  userId: string | undefined,
  pin: string | undefined,
  confirmPin: string | undefined,
  isDriver: number | 0,
) {
  if (!userId || !pin || !confirmPin) {
    throw missingFieldsError;
  }

  if (!isValidPIN(pin)) {
    throw ErrorMessage.PINValidation;
  }

  if (pin && pin !== confirmPin) {
    throw ErrorMessage.PINsUnmatched;
  }

  const api = new UserApi();
  return await api.resetPINByUserId(userId, pin, confirmPin, isDriver);
}

export const isShipperUser = (user: User) => user.userType === UserType.SHIPPER;
export const isDemandAddaUser = (user: User) => user.userType === UserType.DEMAND_ADDA;
export const isInternalUser = (user: User) => user.userType === UserType.INTERNAL;

export const getLoggedInUser = (): WithRequired<User, 'preferences'> => {
  try {
    return JSON.parse(LocalCache.fetchUser() ?? '') as WithRequired<User, 'preferences'>;
  } catch {
    return {} as WithRequired<User, 'preferences'>;
  }
};

export const isLoggedIn = () => LocalCache.fetchToken() !== null;
